// register globally
// import Sticky from 'vue-sticky-directive'

import Vue from 'vue/dist/vue.js';
// Vue.use(Sticky)


// import Vue from 'vue';


import nanoajax from 'nanoajax';

export const app = new Vue({
  el: '#ctracker',
  data: {
    guid: '',
    loaded: false,
    active_sheet: 0,
    ss: {
      staleness_hash: null,
      sheets: [],
    },
    resumes: {},
    selected_resume_ids: [],
    table_widths: [],
    job_selector: null,
  },

  props: {
    // props on App roots don't work in this vue version, so the default is the value.
    pollingInterval: {
      type: Number,
      default: 30
    },
  },

  watch: {
    job_selector(nv,ov) {
      if (nv && nv!='null') {
        window.location.href="/trackers/"+nv;
      }
    }
  },

  methods: {
    style_to_class: function(style) {
      var k = []
      if (style.fw=="bold") k.push("fw-bold");
      if (style.heading) k.push("fw-bold heading bg-yellow-orange px-2 py-3 fs-5");
      if (style.border) k.push("bordered")
      if (style.text_align=="right") k.push("text-end")
      return k;
    },

    colspan_for_cell: function(sheet,cell,col_idx) {
      if (cell.style.width=="full") {
        return sheet.max_col-col_idx+1;
      } else {
        return null;
      }
    },

    url_for_cell: function(cell) {
      var _this = this;
      if (cell.url!='[resume]') {
        if (!/^https?:\/\//i.test(cell.url)) {
          return "https://" + cell.url;
        }
        return cell.url;
      }
      // console.log("CELL: ", cell);
      // var url = "/trackers/" + _this.guid + "/resumes/c-" + cell.meta.cand_id;
      var url = "/resumes/" + _this.ss.job_id + "/" + cell.meta.resume_guid;
      return url
    },
    download_xls: function(target) {
      var url = target + "?fmt=xls";
      window.location.href=url;
    },

    download_zip: function(target) {
      var url = target + "?fmt=zip&resume_ids=" + this.selected_resume_ids.join(",")
      window.location.href=url;
    },

    select_all: function() {
      var _this = this;
      var _ids = Object.values(_this.resumes);
      for(var ridx=0;ridx<_this.resumes_length;ridx++) {
        var _id = _ids[ridx];
        if (_this.selected_resume_ids.indexOf(_id)==-1) {
          _this.selected_resume_ids.push(_id);
        }
      }
    },

    unselect_all: function() {
      var _this = this;
      _this.selected_resume_ids = [];
    },

    toggle_resume_id(resume_id) {
      var _this = this;
      if (_this.selected_resume_ids.indexOf(resume_id)==-1) {
        _this.selected_resume_ids.push(resume_id);
      } else {
        const idx = _this.selected_resume_ids.indexOf(resume_id);
        if (idx > -1) {
          _this.selected_resume_ids.splice(idx, 1);
        }
      }
    },

    row_empty(row) {
      if (!row) return true;
      const _this = this;
      var r = true;
      for(var i=0;i<row.length;i++) {
        if (row[i].text && (''+row[i].text).length>0) return false;
      };

      return r;
    },

    update(callback) {
      if (document.getElementById("ctracker")) {
        var _this = this;
        var url = window.location.href;
        if (_this.ss.staleness_hash) url+="?sh="+_this.ss.staleness_hash;

        nanoajax.ajax({url: url, headers: { 'X-Nano': true }}, function (code, responseText) {
          // may return 304 if current OreData::Spreadsheet has same staleness_hash
          if (code==200) {
            console.log("Updating.");
            var resp = JSON.parse(responseText);
            _this.ss = resp;
            _this.extract_resumes();

            typeof callback === 'function' && callback();
          }
          if (code==304) console.log("Not updating; not modified.");
          if (code==403) {
            console.log("Kicked out!");
            var url = "/trackers/" + _this.guid + "/login/new";
            window.location.href = url;
          }

          Vue.nextTick( function() {
            for(var i=0;i<_this.$refs.sheets.length;i++) {
              _this.table_widths[i] = { width: _this.$refs.sheets[i].clientWidth + "px" }
            }
            console.log("@$nextTick()", _this.table_widths);
            _this.$forceUpdate();


          });

        })
      }
    },

    extract_resumes() {
      var _this = this;
      var h = {};
      if (_this.ss.sheets.length==0) return h;
      for(var r=0;r<_this.ss.sheets[0].rows.length;r++) {
        var row=_this.ss.sheets[0].rows[r];
        for(var c=0;c<row.length;c++) {
          var cell=row[c];
          if (cell.meta && cell.meta.resume_id) {
            h[cell.text] = cell.meta.resume_id;
          }
        }

      }
      _this.resumes = h;
    },

    resume_url(resume_id) {
      return "/trackers/" + this.guid + "/resumes/" + resume_id;
    },

    org_name() {
      const _this = this;
      if (_this.ss.sheets.length==0) return '';
      return _this.org_name;
    },
    job_title() {
      const _this = this;
      if (_this.ss.sheets.length==0) return '';
      return _this.ss.sheets[0].rows[4][0].text;
    },

    table_width(idx) {
      console.log("REFS",idx,this.$refs,this.$refs[idx]);
      if (this.$refs.sheets) {
        var cw = this.$refs.sheets[idx].scrollWidth;
        console.log("WIDTH: ",cw);
        return cw;
      }
    },

    onDummyScroll(idx) {
      const _this = this;
      _this.$refs.real_scroll[idx].scrollLeft = _this.$refs.dummy_scroll[idx].scrollLeft;
      _this.$refs.dummy_scroll_btm[idx].scrollLeft = _this.$refs.dummy_scroll[idx].scrollLeft;
    },

    onDummyScrollBtm(idx) {
      const _this = this;
      _this.$refs.real_scroll[idx].scrollLeft = _this.$refs.dummy_scroll_btm[idx].scrollLeft;
      //_this.$refs.dummy_scroll[idx].scrollLeft = _this.$refs.dummy_scroll_btm[idx].scrollLeft;
    },

    onRealScroll(idx) {
      const _this = this;
      //_this.$refs.dummy_scroll[idx].scrollLeft = _this.$refs.real_scroll[idx].scrollLeft;
      _this.$refs.dummy_scroll_btm[idx].scrollLeft = _this.$refs.real_scroll[idx].scrollLeft;
    },

    rectifyHeight(idx) {
      const _this = this;
      if (_this.$refs.real_scroll) {
        var el = _this.$refs.real_scroll[idx];

        var viewport_offset = el.getBoundingClientRect();
        var window_height = window.innerHeight;
        var top = viewport_offset.top;
        console.log("top",top,window_height);
        var new_height = window_height - top;

        return "height:"+new_height+"px";
      }

    },

    forceUpdate() {
      this.$forceUpdate();
    },


  },

  computed: {


    resumes_length() {
      return Object.keys(this.resumes).length
    },

    // return hash of name/resume_id
    selected_resume_text() {
      var _this = this;
      if (_this.resumes_length == _this.selected_resume_ids.length) return "all resumes";
      if (_this.selected_resume_ids.length==0) return "no resumes";
      if (_this.selected_resume_ids.length==1) return "1 resume";
      return _this.selected_resume_ids.length + " resumes";
    },

  },

  mounted() {

    var _this = this;
    // extract guid from url because apps can't have props.  grr.  should just move this to a component instead probably.
    var guid_regex = /([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/i;
    var r = guid_regex.exec(window.location.href);
    _this.guid = r[0];

    _this.update( function() {
      _this.loaded = true;

      // preselect all resumes
      _this.selected_resume_ids = Object.values(_this.resumes);

      //_this.$forceUpdate();
      // cheesy poll for now
      var pi = parseInt(_this.pollingInterval) * 1000;
      window.setInterval( _this.update, pi );

      window.addEventListener('resize', function() { _this.forceUpdate() });
    })
  },
  unmounted() {
    window.removeEventListener('resize', function() { _this.forceUpdate() });

  },

})
